import Swiper from "swiper";
import { Autoplay, EffectFade} from 'swiper/modules';

const parallaxSections = document.querySelectorAll('.section-cta');

const handleParallaxStyles = (section) => {
    const boundingRect = section.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const percentage = Math.max(
        0,
        Math.min(
            100,
            ((viewportHeight - boundingRect.top) /
                (viewportHeight + boundingRect.height)) *
                100
        )
    );

    const backgroundDiv = section.querySelector(
        '.section-cta__background-image--moving'
    );
    const backgroundBlackBackground = section.querySelector(
        '.section-cta__background-opacity-black--moving'
    );

    if (backgroundDiv) {
        backgroundDiv.style.backgroundPosition = `50% ${parseFloat(
            percentage
        )}%`;
    }

    if (percentage >= 1) {

        if (backgroundBlackBackground) {
            const opacityValue = percentage > 55 ? 66 : percentage + 10
            backgroundBlackBackground.style.opacity = `${parseFloat(
                opacityValue
            )}%`;
        }
    } else {
        if (backgroundBlackBackground) {
            backgroundBlackBackground.style.opacity = `0%`;
        }
    }
}

if (parallaxSections && parallaxSections.length > 0) {
    parallaxSections.forEach(section => {
        window.addEventListener('scroll', () => {
            handleParallaxStyles(section);
        });
        handleParallaxStyles(section);

        const triggerContactBtn = section.querySelector(".js-trigger-side-menu");

        if (triggerContactBtn) {
            const showFormButton = document.querySelector(".js-show-form");
            if (showFormButton) {
                triggerContactBtn.addEventListener("click", () => {
                    showFormButton.click();
                })
            }
        }
    });
}

const ctaBackgroundSliders = document.querySelectorAll('.section-cta__background-slider');

Swiper.use([Autoplay, EffectFade]);
let ctaArray = [];

const initSwiper = () => {
    ctaBackgroundSliders.forEach((section, i) => {
        let init = false;
        section.classList.add(`section-cta__background-slider-${i}`);
        if (section.querySelector('.swiper-wrapper')) {
            const reviewCarousel = new Swiper(`.section-cta__background-slider-${i}`, {
                slidesPerView: 'auto',
                slideActiveClass: 'is-active',
                slidesPerView: 1,
                spaceBetween: 0,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                speed: 5000,
                freeMode: true,
                freeModeMomentum: false,
                freeModeMomentumBounce: false,
                loop: 1,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                },
            });
            ctaArray.push(reviewCarousel);
        }
    })
}

initSwiper();